import React from 'react'
import Heading from '@/components/atoms/Heading'
import MarkdownContent from '@/components/atoms/MarkdownContent'
import * as styles from './styles.module.css'

function ContactUs({ additional_addresses, heading, main_address, subheading }: ContactUsType) {
	return (
		<section className={styles.wrapper}>
			<div className="container">
				<div className={styles.inner}>
					<Heading className={styles.headingThree} level={3}>
						{heading}
					</Heading>
					<p>{subheading}</p>
					<div className={styles.main_address}>
						<MarkdownContent content={main_address?.content} />
					</div>

					<div className={styles.lower}>
						<div className={styles.mdWrapper}>
							{additional_addresses?.map((address: RichTextType) => (
								<div className={styles.additional_address} key={address.id}>
									<MarkdownContent content={address.content} />
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default ContactUs

import React from 'react'
import Image from '@/components/atoms/Image'
import MarkdownContent from '@/components/atoms/MarkdownContent'
import * as styles from './styles.module.css'

export default function Hero({ content, background, bg_dark_level = 60 }: HeroType) {
	return (
		<section>
			<div className={styles.container}>
				<Image image={background} className={styles.image} style={{ filter: `brightness(${bg_dark_level}%)` }} />
				<div className={styles.textArea}>{content && <MarkdownContent content={content} />}</div>
			</div>
		</section>
	)
}

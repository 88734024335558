import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/components/organisms/Layout'
import Seo from '@/components/organisms/Seo'
import ContactUs from '@/components/organisms/ContactUs'
import Hero from '@/components/organisms/Hero'

interface Props {
	data?: {
		content: {
			seo?: SeoMetagagsType
			section_hero?: HeroType
			section_contact_us?: ContactUsType
		}
	}
}
const ContactUsPage: React.FC<Props> = ({ data }) => {
	const { content } = data || {}

	return (
		<Layout>
			<div className="background contact">
				<Seo {...content?.seo} />
				<Hero {...content?.section_hero} />
				<ContactUs {...content?.section_contact_us} />
			</div>
		</Layout>
	)
}

export const ContactUsPageQuery = graphql`
	query {
		content: strapiPageContact {
			seo {
				description
				og_image {
					url
				}
				title
			}

			section_hero {
				background {
					localFile {
						childImageSharp {
							gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, JPG])
						}
					}
				}
				bg_dark_level
				content {
					data {
						content
					}
				}
			}

			section_contact_us {
				additional_addresses {
					id
					content {
						data {
							content
						}
					}
				}
				heading

				main_address {
					content {
						data {
							content
						}
					}
				}
				subheading
			}
		}
	}
`

export default ContactUsPage

import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

type Props = {
	image?: GatsbyAsset
	className?: string
	alt?: string | undefined
	style?: {
		filter: string
	}
}

export default function Image({ image, className, alt = '', ...otherProps }: Props) {
	const imageData = image && getImage(image.localFile)
	return imageData !== undefined || imageData !== null ? (
		<GatsbyImage className={className} image={imageData} alt={alt} {...otherProps} />
	) : (
		<p style={{ color: 'red' }}>Please insert image in CMS !</p>
	)
}
